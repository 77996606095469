<script>
    // -- IMPORTS
    import { getImagePath, getMediaPath } from "$src/lib/filePath";
    import { getFileExtension, isArray } from "senselogic-gist";
    import { onMount } from "svelte";
    import Carousel from "./Carousel.svelte";

    // -- VARIABLES

    export let mediaId = '';
    export let mediaStyle = '';
    export let mediaClass = '';
    export let mediaSize = '1920';
    export let mediaBackgroundSize = 'cover';
    export let mediaPosition = { vertical: 'center', horizontal: 'center' }
    export let isHeroSection = false;
    export let mediaPath = '';

    let isLoading = true;
    let firstClick = '';
    let slideCount = 0;
    let activeSlideIndex = 0;
    let isVideoBeingHovered = false;
    let videoFormatArray = [
            ".mp4",
            ".webm",
            ".ogg",
            ".av1"
        ];

    // -- FUNCTIONS

    function restartVideoUnmuted(
        index = 0
        )
    {
        if( !isHeroSection
            && firstClick !== mediaId + index )
        {
            let video = document.querySelector( '#video' + mediaId + index );
            video.muted = false;
            video.currentTime = 0;
            video.autoplay = true;
            video.play();

            setTimeout( () => {
                video.controls = true;
                firstClick = mediaId + index;
            },
            200
            );
        }
    }

    // ~~

    function handleCarouselChange(
        slideInfo
        )
    {
        slideCount = slideInfo.slideCount;
        activeSlideIndex = slideInfo.slideIndex;

        if( !isHeroSection )
        {
            let mediaElement = document.querySelector( '#video' + mediaId + slideInfo.previousSlideIndex );

            if( mediaElement !== null
                && mediaElement.tagName === 'VIDEO' )
            {
                mediaElement.muted = true;
            }
        }
    }

    // ~~

    function toggleIsBeingHovered(
        )
    {
        isVideoBeingHovered = !isVideoBeingHovered;
    }
</script>

<style lang="stylus">
    .carousel-container
    {
        height: 100%;
        min-width: 100%;
        max-width: 80dvw;
    }

    .is-hero-section
    {
        max-width: 100dvw;
    }

    .block-item-video,
    .block-item-image
    {
        height: 100%;
        max-height: 100%;
        width: 100%;

        object-fit: cover;

        color: transparent;
    }

    .is-clickable
    {
        cursor: pointer;
    }

    .carousel-dot-list
    {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX( -50% );

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .carousel-dot
    {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;

        background-color: rgba(255, 255, 255, 0.8);

        transition: all 0.3s ease-in-out;
    }

    .carousel-dot.active
    {
        transform: scale( 1.2 );

        background-color: #fff;
    }
</style>

{#if Array.isArray( mediaPath ) && mediaPath.length > 0 }
    <nav
        class="carousel-container"
        class:is-hero-section={ isHeroSection }
        on:mouseenter={ toggleIsBeingHovered }
        on:mouseleave={ toggleIsBeingHovered }
    >
        <Carousel
            columnCount={ 1 }
            hasDots
            isAutomatic={ !isVideoBeingHovered }
            hasButtons={ false }
            hasCount={ false }
            onChange={ handleCarouselChange }
            transitionDuration={ 700 }
        >
            {#each mediaPath as path, index }
                {#if videoFormatArray.includes( getFileExtension( path ).toLowerCase() ) }
                    <video
                        id={ 'video' + mediaId + index }
                        class="block-item-video carousel-slide { mediaClass }"
                        class:is-clickable={ !isHeroSection }
                        autoplay
                        loop
                        muted
                        disablepictureinpicture
                        style={ mediaStyle }
                        controlslist="nodownload noplaybackrate"
                        on:click={ () => restartVideoUnmuted( index ) }
                    >
                        <source src={ getMediaPath( path ) } type="video/mp4"/>
                    </video>
                {:else}
                    <div
                        class="block-item-image carousel-slide { mediaClass }"
                        style="
                                background: url( { getImagePath( path, mediaSize ) } ) no-repeat { mediaPosition[ index ]?.horizontal || 'center' }  {  mediaPosition[ index ]?.vertical || 'center' } / { mediaBackgroundSize },
                                            url( { getImagePath( path, '360' ) } ) no-repeat { mediaPosition[ index ]?.horizontal || 'center' }  {  mediaPosition[ index ]?.vertical || 'center' } / { mediaBackgroundSize || 'center' };
                                { mediaStyle }
                            "
                    />
                {/if}
            {/each}
        </Carousel>
    </nav>
{:else}
    {#if videoFormatArray.includes( getFileExtension( mediaPath ).toLowerCase() ) }
        <video
            id={ "video" + mediaId }
            class="block-item-video { mediaClass }"
            class:is-clickable={ !isHeroSection }
            autoplay
            loop
            muted
            disablepictureinpicture
            style={ mediaStyle }
            controlslist="nodownload noplaybackrate"
            on:click={ restartVideoUnmuted }
        >
            <source src={ getMediaPath( mediaPath ) } type="video/mp4"/>
        </video>
    {:else}
        <div
            class="block-item-image { mediaClass }"
            style="
                    background: url( { getImagePath( mediaPath, mediaSize ) } ) no-repeat { mediaPosition.horizontal }  { mediaPosition.vertical } / { mediaBackgroundSize },
                                url( { getImagePath( mediaPath, '360' ) } ) no-repeat { mediaPosition.horizontal }  { mediaPosition.vertical } / { mediaBackgroundSize };
                    { mediaStyle }
                "
        />
    {/if}
{/if}
