<script>
  import { getImagePath } from "$src/lib/filePath";
  import { getLocalizedText } from "senselogic-gist";
  import AnimatedContainer from "../element/AnimatedContainer.svelte";
  import { languageTagStore } from "$src/lib/store/languageTagStore";
  import Media from "../element/Media.svelte";

 export let service;
 export let imageSide;

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .hub-sub-block-item.text-and-image
    {
        width: 100%;

        display: flex;
        flex-direction: column-reverse;

        +media( desktop )
        {
            max-width: 77vw;

            flex-direction: row;
            gap: 5rem;
        }
    }

    .hub-sub-block-item.text-and-image.left
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .hub-sub-block-item.text-and-image >div
    {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    .hub-sub-block-item.text-and-image >div:first-child
    {
        margin-top: 2.5rem;
    }

    .hub-sub-block-title
    {
        display: none;

        +media( desktop )
        {
            margin-bottom: 2rem;

            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
        }
    }

    .hub-sub-block-title.mobile
    {
        margin-top: 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        +media( desktop )
        {
            display: none;
        }
    }

    .sub-block-title-img-container
    {
        height: 3rem;
    }

    :global( .hub-sub-block-title > .sub-block-title-img )
    {
        height: 3rem;
    }

    .hub-sub-block-title > p
    {
        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 2rem;
            letter-spacing: 0.16rem;
        }
    }

    .hub-sub-block-text
    {
        margin-bottom: 1rem;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }

    :global( .hub-sub-block-text > .hub-sub-block-img )
    {
        height: 2.5rem;
    }

    .hub-sub-block-text p
    {
        line-height: 2rem;
        font-size: 1.25rem;
        letter-spacing: 0.03rem;

        +media( desktop )
        {
            line-height: 2.25rem;
            font-size: 1.5rem;
        }
    }

    .hub-sub-block-image
    {
        height: 18.75rem;
        width: 100%;
        aspect-ratio: 0.5;

        object-fit: cover;

        +media( desktop )
        {
            height: 40rem;
            min-width: 40rem;
        }
    }

    .hub-sub-block-item.grid
    {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        +media( desktop )
        {
            align-items: flex-start;
        }
    }

    .hub-sub-block-item.grid > .sub-block-grid-img
    {
        height: 2.5rem;
        width: 2.5rem;

        +media( desktop )
        {
            height: 3rem;
            width: 3rem;
        }
    }

    .hub-sub-block-item.grid >p
    {
        line-height: 2rem;
        font-size: 1.25rem;
        font-weight: 400;
        font-style: normal;
    }
</style>

{#if service.title }
    <AnimatedContainer>
        <div class="hub-sub-block-title mobile">
            {#if service.iconPath }
                <Media
                    mediaClass="hub-sub-block-img"
                    mediaPath={ service.iconPath }
                />
            {/if}
            {#if service.title }
                <p class="color-light-gold text-center">{ getLocalizedText( service.title, $languageTagStore ) }</p>
            {/if}
        </div>
    </AnimatedContainer>
{/if}

<div class="hub-sub-block-item text-and-image { imageSide }">
    <div class="hub-sub-block-title-text">
        {#if service.title }
            <AnimatedContainer>
                <div class="hub-sub-block-title" style="margin-top: 0;">
                    {#if service.iconPath }
                        <div class="sub-block-title-img-container">
                            <Media
                                mediaClass="sub-block-title-img"
                                mediaPath={ service.iconPath }
                            />
                        </div>
                    {/if}
                    {#if service.title }
                        <p class="color-light-gold">{ getLocalizedText( service.title, $languageTagStore ) }</p>
                    {/if}
                </div>
            </AnimatedContainer>
        {/if}
        {#if service.textArray }
            {#if service.textArray.length > 0 }
                {#each service.textArray as description }
                    <AnimatedContainer>
                        <div class="hub-sub-block-text">
                            <p class="color-light-grey">{ getLocalizedText( description, $languageTagStore ) }</p>
                        </div>
                    </AnimatedContainer>
                {/each}
            {/if}
        {/if}
    </div>
    <AnimatedContainer>
        <div class="hub-sub-block-image">
            {#if service.mediaPath || service.mediaPathArray }
                <div style="width: 100%; height: 100%;">
                    <Media
                        mediaClass="sub-block-img"
                        mediaPath={ service.mediaPath || service.mediaPathArray }
                        mediaId={ service.id }
                    />
                </div>
            {/if}
        </div>
    </AnimatedContainer>
</div>
