<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { onMount } from 'svelte';
    import Marker from './Marker.svelte';
    import { getImagePath } from '$src/lib/filePath';
    import Loading from '../element/Loading.svelte';
    import { createLoadObserver } from '$lib/base'

    // -- CONSTANTS

    const onLoad = createLoadObserver(
        () =>
        {
            let mapContainer = document.querySelector( '.map-content' );

            mapContainer.style.minHeight = `0`;
            isLoading = false;
        }
    );

    // -- VARIABLES

    export let networkingArray = [];
    let imageWidth = 1430;
    let imageHeight = 612;
    let imageElement;
    let isLoading = true;

    // -- FUNCTIONS

    function updateHubArray(
        )
    {
        networkingArray = networkingArray.map(
            city =>
            {
                let x, y;

                if ( city.coordinateMap )
                {
                    let responsiveCoordinateRefinement = city.coordinateMap;
                    x = Number( responsiveCoordinateRefinement.x || 0 );
                    y = Number( responsiveCoordinateRefinement.y || 0 );
                }

                return (
                    {
                        ...city,
                        x,
                        y
                    }
                    );
            }
            );
    }

    // ~~

    function handleResizeEvent(
        )
    {
        imageWidth = imageElement?.offsetWidth;
        imageHeight = imageElement?.offsetHeight;

        updateHubArray();
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            window.addEventListener( 'resize', handleResizeEvent );

            return () =>
                {
                    window.removeEventListener( 'resize', handleResizeEvent );
                }
        }
        );

    // ~~

    onMount( updateHubArray );

    // ~~

    $: updateHubArray();
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .map-container
    {
        +media( desktop )
        {
            max-width: 77vw;
        }
    }

    .map-content
    {
        position: relative;

        min-height: 26rem;
    }

    .map-image
    {
        height: auto;
        width: 100%;

        object-fit: cover;
        -webkit-user-select:none;
        -webkit-touch-callout:none;
    }
</style>

<div class="map-container" class:is-admin-enabled={ $enabledAdminView }>
    <AdminEditButton
        type="network-city"
    />

    <div class="map-content">
        <img
            use:onLoad
            bind:this={ imageElement }
            on:load={ handleResizeEvent }
            on:contextmenu={ () => false }
            class="map-image"
            src={ getImagePath( "/image/networking/map.svg" ) }
            alt=""
            />
        {#if isLoading }
            <Loading isCentered />
        {:else}
            {#each networkingArray as hub, hubIndex }
                <Marker marker={ hub } markerIndex={ hubIndex }/>
            {/each}
        {/if}
    </div>
</div>
