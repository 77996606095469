<script>
    import { getProcessedMultilineTranslatedText } from '$lib/base.js';
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { pageStore } from '../../store/pageDataStore';
    import { fetchData } from '../../base';
    import { onMount } from 'svelte';
    import ContactButtons from '../element/ContactButtons.svelte';
    import urlStore from '$src/lib/store/urlStore';
    import FullLoading from '../Layout/FullLoading.svelte';
  import Media from '../element/Media.svelte';

    // -- VARIABLES

    export let founderArray = [];
    let founderImage = $pageStore.block.FoundersSection;
    let isLoading = true;
    let isExpanded = {};
    let hasOverflow = {};
    let element;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let data =
                await fetchData(
                    '/api/block/get-by-slug',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                slug: "founder-image-path",
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.block.FoundersSection = data.blockArray[ 0 ]
            founderImage = data.blockArray[ 0 ]
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    function toggleReadMore(
        biographyId
        )
    {
        isExpanded = { ...isExpanded, [ biographyId ]: !isExpanded[ biographyId ] };
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
           if( founderImage === undefined ) {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    );

    $: element, founderArray.forEach(
                    ({ id }) => {
                        if ( element )
                        {
                            hasOverflow[id] = element.scrollHeight > element.clientHeight;
                        }
                    });
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .founders-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            margin: 0;
            max-width: 77vw;
            border: none;
            padding-bottom: 2.5rem;

            gap: 4rem;
        }
    }

    .founders-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .founders-content
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 50%;

            flex: 1;
        }
    }

    .founders-image-container
    {
        height: 100%;
        width: 100%;
        aspect-ratio: 4 / 3;

        +media( desktop )
        {
            height: 50rem;
        }
    }

    :global( .founders-image )
    {
        height: 100%;
        width: 100%;
    }

    .founders-content-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .founders-content-heading-subtitle
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #E6E9EE;
    }

    .founders-content-wrapper
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .description-text
    {
        position: relative;

        overflow: hidden;

        display: -webkit-box;
        -webkit-box-orient: vertical;

        max-height: 8rem;

        transition: all .3s;
    }

    .description-text.expanded
    {
        max-height: 100%;
    }
</style>

<section class="founders-section" id="founders">
    {#if isLoading }
        <FullLoading />
    {:else}
        <span class="heading-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
            <AnimatedText text={ getLocalizedText( $textStore[ 'our-team-label' ], $languageTagStore ) } />
            <AdminEditButton
                hash="founders-label"
                inset="-2.5rem -1rem auto auto"
            />
        </span>

        <div class="founders-container" class:is-admin-enabled={ $enabledAdminView }>
            <div class="founders-image-container">
                <Media
                    mediaClass="founders-image"
                    mediaPath={ founderImage?.mediaPath ?? founderImage?.mediaPathArray }
                    isHeroSection={ true }
                />
            </div>
            <AdminEditButton
                type="block"
                id={ founderImage.id }
            />

            <div class="founders-content-wrapper">
                {#each founderArray as { id, name, position, biography, emailAddress, linkedinLink } }
                    <div class="founders-content" class:is-admin-enabled={ $enabledAdminView }>
                        <AdminEditButton
                            hash={ id }
                        />

                        <div>
                            <p class="founders-content-heading-title"><AnimatedText text={ name }/></p>
                            <p class="founders-content-heading-subtitle"><AnimatedText text={ getLocalizedText( position, $languageTagStore ) }/></p>
                        </div>

                        <AnimatedContainer>
                            <div bind:this={element} class="description-text { isExpanded[id] ? 'expanded' : '' }">
                                {@html getProcessedMultilineTranslatedText( biography || '', $languageTagStore ) }
                            </div>

                            {#if hasOverflow[id] }
                                <span
                                    on:click={() => toggleReadMore(id)}
                                    style="cursor: pointer; text-decoration: underline;"
                                    >
                                    {
                                        isExpanded[id]
                                            ? 'Read Less'
                                            : 'Read More'
                                    }
                                </span>
                            {/if}
                        </AnimatedContainer>

                        <AnimatedContainer>
                            <ContactButtons
                                { emailAddress }
                                { linkedinLink }
                            />
                        </AnimatedContainer>
                    </div>
                {/each}
            </div>
        </div>
    {/if}
</section>
