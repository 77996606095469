<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { websiteUrl } from '$lib/base.js';
    import Seo from '$lib/component/element/Seo.svelte';
    import 'leaflet.markercluster/dist/MarkerCluster.css';
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
    import 'leaflet/dist/leaflet.css';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { fetchData } from '../base';
    import { pageStore } from '../store/pageDataStore';
    import { languageArrayStore } from '../store/languageArrayStore';
    import urlStore from '../store/urlStore';
    import FullLoading from '../component/Layout/FullLoading.svelte';
    import HubService from '../component/hubService/HubService.svelte';
  import Media from '../component/element/Media.svelte';

    // -- VARIABLES

    export let slug;
    let isLoading = true;

    let page = $pageStore.page.hubPage[ slug ];
    let hub = $pageStore.block.hub[ slug ];
    let serviceIncludedArray =  $pageStore.block.serviceIncluded[slug];
    let serviceRequestArray = $pageStore.block.serviceRequest[slug];
    let serviceAtLocationArray = $pageStore.block.serviceAtLocation[slug];

    let leaflet;
    let map;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let mediaPath = '';
    let url = '';

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let [ pageData, hubData, { hubArray } ] = await Promise.all(
                [
                    fetchData(
                        '/api/page/get-by-slug',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    slug: slug
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/hub/get-by-slug',
                        {
                            method: 'POST',
                            body:  JSON.stringify(
                                {
                                    slug: slug
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/hub-service',
                        {
                            method: 'POST',
                            body:  JSON.stringify(
                                {
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                ]
            );

            page = pageData.page;
            hub = hubData.hub;

            let filteredServiceIncludedArray = hubArray.filter( ( service ) => hub.serviceIncludedArray.includes( service.id ) );
            let filteredserviceRequestArray = hubArray.filter( ( service ) => hub.serviceRequestArray.includes( service.id ) );
            let filteredserviceAtLocationArray = hubArray.filter( ( service ) => hub.serviceByLocationArray.includes( service.id ) );

            serviceIncludedArray = filteredServiceIncludedArray;
            serviceRequestArray = filteredserviceRequestArray;
            serviceAtLocationArray = filteredserviceAtLocationArray;

            $pageStore.page.hubPage[ slug ] = pageData;
            $pageStore.block.hub[slug] = hub;
            $pageStore.block.serviceIncluded[slug] = filteredServiceIncludedArray;
            $pageStore.block.serviceRequest[slug] = filteredserviceRequestArray;
            $pageStore.block.serviceAtLocation[slug] = filteredserviceAtLocationArray;

            metaTitle = pageData.metaTitle ?? pageData.title;
            metaDescription = pageData.metaDescription ?? pageData.description;
            metaKeywords = pageData.metaKeywords;
            mediaPath = pageData.mediaPath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    async function initializeMap(
        container
        )
    {
        leaflet = await import( 'leaflet' );

        map = leaflet.map( container,
            {
                scrollWheelZoom: false,
                attributionControl: false,
                maxZoom: 18,
                minZoom: 10,
            }
            ).setView( [ hub.latitude, hub.longitude ], 18 );

        await import( 'leaflet.markercluster/dist/leaflet.markercluster-src.js' );

        // L is a variable provided by Leaflet.

        let customIcon = L.icon(
            {
                iconUrl: '/image/icon/map_pin.svg',
                iconSize: [38, 95],
                iconAnchor: [22, 94],
                popupAnchor: [-3, -76],
            }
            );

        let marker = L
            .marker( [ hub.latitude, hub.longitude ], { icon: customIcon } );

        leaflet.tileLayer(
            'https://api.mapbox.com/styles/v1/fcfabiano/clvfckdmm057z01pk63kocnzx/tiles/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmNmYWJpYW5vIiwiYSI6ImNsdmY4bHIxZTBiNDQyaW4wMmpjanRnY2sifQ.QF-FqB8dgjNk1JRDDu3nfQ',
            {
                attribution: '© OpenStreetMap contributors'
            }
            )
            .addTo( map );

        map.on('moveend', function() {
    }
    );

        marker.addTo( map );
    }

    // ~~

    function resizeMap(
        )
    {
        if ( map )
        {
            map.invalidateSize();
        }
    }

    // ~~

    function mapAction(
        container
        )
    {
        initializeMap( container );
    }

    // ~~

    async function mountComponent(
        slug
        )
    {
        isLoading = true;

        await loadData();
    }

    // -- STATEMENTS

    $:  mountComponent( slug )

    $: if ( isLoading === false
            && $urlStore.pathname.includes( `/hub/${ slug }` )
            && hub !== undefined )
        {
            document.title = `Hub - ${ getLocalizedText( hub.title ) }`;
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .hub-section
    {
        width: 100%;
        padding: 5rem 0;

        display: flex;
        flex-direction: column;
        gap: 5rem;
        align-items: center;
        align-self: stretch;
    }

    .hub-title
    {
        padding: 0 1.5rem;

        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .hub-description
    {
        padding: 0 1.5rem;

        line-height: 2.09rem;
        font-size: 1.1rem;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.2px;
        text-align: left;

        +media( desktop )
        {
            max-width: 77vw;

            line-height: 2.25rem;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .hub-block-list
    {
        padding: 0 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;

        +media( desktop )
        {
            max-width: 77vw;

            gap: 5rem;
        }
    }

    .hub-image-container
    {
        width: 100%;
    }

    :global( .hub-image-container .hub-img )
    {
        height: 32.23rem;
        width: 100%;

        object-fit: cover;

        +media( desktop )
        {
            height: 44.875rem;
        }
    }

    .hub-block-item.text-and-image
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        +media( desktop )
        {
            max-width: 85vw;
        }
    }

    .hub-block-item.text-and-image >div
    {
        margin-top: 1rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;

        +media( desktop )
        {
            width: 80vw;
        }
    }

    .hub-block-item.grid
    {
        +media( desktop )
        {
            max-width: 85vw;
        }
    }

    .hub-block-item.grid >div
    {
        margin-top: 4rem;

        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        +media( desktop )
        {
            margin-top: 5rem;
            max-width: 85vw;

            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 4rem;
        }
    }

    .hub-block-title
    {
        width: 100%;

        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-align: left;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            text-align: center;
        }
    }

    .hub-block-description
    {
        margin-top: 1rem;

        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 400;
        font-style: normal;
        text-align: left;

        +media( desktop )
        {
            margin-top: 0.5rem;

            text-align: center;
        }
    }

    .map
    {
        margin-bottom: 3.5rem;
        width: 100%;
        aspect-ratio: 3/3;

        filter: sepia(30%);

        +media( desktop )
        {
            height: 42.8125rem;
        }
    }

    .hub-sub-block-item.carousel
    {
        margin: auto;
        width: 18.75rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            width: 26.625rem;
        }
    }

    .hub-sub-block-item-image.carousel
    {
        height: 18.75rem;

        align-self: stretch;
        object-fit: cover;

        +media( desktop )
        {
            height: 28.75rem;
        }
    }

    .hub-sub-block-item-content.carousel
    {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .other-hub
    {
        margin-bottom: 5rem;
    }

    .text-center
    {
        text-align: center;
    }

    .anchor-button
    {
        width: 100%;
        min-width: 10.6125rem;
        border: 1px solid lightGoldColor;
        border-radius: 0;
        padding: 0.75rem 1.25rem;

        background-color: darkGreyColor;

        text-align: center;
        text-wrap: nowrap;

        font-size: 1rem;
        text-transform: uppercase;
        color: lightGoldColor;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        +media( smaller-desktop )
        {
            width: 100%;
            max-width: unset;
        }
    }

    .anchor-button.invert-color
    {
        border: 1px solid lightGoldColor;

        background: lightGoldColor;

        color: darkGreyColor;
    }

    .anchor-button:hover,
    .anchor-button:focus
    {
        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    .anchor-button.invert-color:hover,
    .anchor-button.invert-color:focus
    {
        background-color: darkGreyColor;

        color: lightGoldColor;
    }
</style>

<svelte:window on:resize={ resizeMap }/>

{#key $urlStore.pathname }
    {#if isLoading }
        <FullLoading />
    {:else}
        <Seo
            metaTitle={ metaTitle }
            metaDescription={ metaDescription }
            metaKeywords={ metaKeywords }
            url={ url }
            mediaPath={ mediaPath }
            languageData={ $languageArrayStore }
            path=''
        />

        <section class="hub-section" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="hub"
                id={ hub.id }
                inset="2rem 10rem auto auto"
            />
            {#if page.title }
                <p class="hub-title color-light-gold">
                    <AnimatedText text={ getLocalizedText( page.title, $languageTagStore ) }/>
                </p>
            {/if}
            {#if page.mediaPath || page.mediaPathArray }
                <div class="hub-image-container">
                    <AnimatedContainer>
                        <Media
                            mediaPath={ page.mediaPath ?? page.mediaPathArray }
                            mediaClass="hub-img"
                            isHeroSection={ true }
                        />
                    </AnimatedContainer>
                </div>
            {/if}
            {#if page.description }
                <p class="hub-description color-light-grey">
                    <AnimatedText text={ getLocalizedText( page.description, $languageTagStore ) } />
                </p>
            {/if}
            <div class="hub-block-list">
                {#if serviceIncludedArray.length > 0 }
                    <AnimatedContainer>
                        <p class="hub-block-title color-light-gold">{ getLocalizedTextBySlug( 'services-included-label', $languageTagStore ) }</p>
                    </AnimatedContainer>

                    <AnimatedContainer>
                        <p class="hub-block-description color-light-grey">{ getLocalizedTextBySlug( 'services-included-description-label', { hubTitle: hub.title }, $languageTagStore ) }</p>
                    </AnimatedContainer>

                    {#each serviceIncludedArray as service, index }
                        <HubService service={ service } imageSide={ index % 2 === 0 ? 'left' : 'right' } />
                    {/each}
                {/if}

                {#if serviceRequestArray.length > 0 }
                    <AnimatedContainer>
                        <p class="hub-block-title color-light-gold">{ getLocalizedTextBySlug( 'services-on-request-label', $languageTagStore ) }</p>
                    </AnimatedContainer>

                    <AnimatedContainer>
                        <p class="hub-block-description color-light-grey">{ getLocalizedTextBySlug( 'services-on-request-description-label', $languageTagStore ) }</p>
                    </AnimatedContainer>

                    {#each serviceRequestArray as service, index }
                        <HubService service={ service } imageSide={ index % 2 === 0 ? 'left' : 'right' } />
                    {/each}
                {/if}

                {#if serviceAtLocationArray.length > 0 }
                    <AnimatedContainer>
                        <p class="hub-block-title color-light-gold">{getLocalizedTextBySlug( 'services-offered-at-location-label', $languageTagStore ) }</p>
                    </AnimatedContainer>

                    {#each serviceAtLocationArray as service, index }
                        <HubService service={ service } imageSide={ index % 2 === 0 ? 'left' : 'right' } />
                    {/each}
                {/if}
            </div>
        </section>

        {#if hub.latitude && hub.longitude }
            <div class="map" style="margin-bottom: 5rem;" use:mapAction/>
        {/if}
    {/if}
{/key}
